//- React Imports
import React from 'react';

const LandingV2 = () => {
	return (
		<>
		</>
	);
};

export default LandingV2;
