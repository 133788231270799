import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className='footer'>
            <div className='footer-div-1'>
            </div>
            <div className='footer-div-2'>
                <a href="https://www.soroschain.com/" target="_blank" rel="noreferrer">2024 with ♥ by Soros Devs</a>
            </div>
        </footer>
    )
}

export default Footer
