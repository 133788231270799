import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Web3 from 'web3'; // Asegúrate de que Web3 esté disponible
import styles from './BuyModal.module.css';
import { buyNFT, approveTokens } from '../../store/reducer/purchaseNFT_reducer/indexNFT';
import ImageLoader from '../Loader/Loader';
import tribeNFT from '../../store/reducer/purchaseNFT_reducer/tribeNFT.json'; // ABI y dirección del contrato

const BuyModal = ({
    artist,
    user,
    name,
    colection,
    price,
    image,
    video,
    onClose,
    address,
    connected,
    nftId,
    cutoffDate,
}) => {
    const [mintCount, setMintCount] = useState(1);
    const incrementRef = useRef(null);
    const decrementRef = useRef(null);

    const web3 = new Web3(window.ethereum);
    const nftContract = new web3.eth.Contract(tribeNFT.nft1.nftAbi, tribeNFT.nft1.nftAddress);

    // Estado para almacenar el MaxSupply, totalSupply, whitelistMode y whitelistMintLimit
    const [maxSupply, setMaxSupply] = useState(0);
    const [totalSupply, setTotalSupply] = useState(0);
    const [whitelistMode, setWhitelistMode] = useState(false);
    const [whitelistMintLimit, setWhitelistMintLimit] = useState(0);

    //888888888888888888888888888888888888888888888888888888888888888888//
    //- DATE CUTOFF -//

    const update = true;
    const dateCutOff = new Date(cutoffDate);
    const [dateNow, setDateNow] = useState(Date.now());
    const [dateRemaining, setDateRemaining] = useState(0);
    const [daysRemaining, setDaysRemaining] = useState(0);
    const [hoursRemaining, setHoursRemaining] = useState(0);
    const [minutesRemaining, setMinutesRemaining] = useState(0);
    const [secondsRemaining, setSecondsRemaining] = useState(0);
    const [done, setDone] = useState(false);

    //- Get Date Cutoff
    const cutoffTime = dateCutOff.getTime();
    const cutoffYear = dateCutOff.getFullYear();
    const cutoffMonth = dateCutOff.getMonth() + 1; // Los meses en JavaScript son 0-11, por lo que sumamos 1
    const cutoffDay = dateCutOff.getDate();
    const cutoffHour = dateCutOff.getHours();
    const cutoffMinutes = dateCutOff.getMinutes();

    useEffect(() => {
        setInterval(() => {
            setDateNow(Date.now());
        }, 1000);
    }, [update]);

    useEffect(() => {
        if (dateNow === cutoffTime || dateNow > cutoffTime) {
            setDone(true);
        }
        if (!done) {
            setDateRemaining(cutoffTime - dateNow);
            setDaysRemaining(Math.floor(dateRemaining / (1000 * 60 * 60 * 24)));
            setHoursRemaining(
                Math.floor((dateRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            );
            setMinutesRemaining(
                Math.floor((dateRemaining % (1000 * 60 * 60)) / (1000 * 60)),
            );
            setSecondsRemaining(Math.floor((dateRemaining % (1000 * 60)) / 1000));
        }
    });
    //888888888888888888888888888888888888888888888888888888888888888888//

    // Verificar si está en modo whitelist al cargar el componente
    useEffect(() => {
        const fetchWhitelistData = async () => {
            const isWhitelistMode = await nftContract.methods.whitelistMode().call();
            setWhitelistMode(isWhitelistMode);

            // Si está en modo whitelist, obtener el límite de minting del usuario
            if (isWhitelistMode) {
                const userAddress = await web3.eth.getAccounts();
                const limit = await nftContract.methods.whitelistMintLimit(userAddress[0]).call();
                setWhitelistMintLimit(parseInt(limit, 10));
            }
        };
        fetchWhitelistData();
    }, [nftContract]);

    // Obtener el MaxSupply y totalSupply al cargar el componente
    useEffect(() => {
        const fetchSupplies = async () => {
            const max = await nftContract.methods.maxSupply().call();
            const total = await nftContract.methods.totalSupply().call();
            setMaxSupply(parseInt(max, 10));
            setTotalSupply(parseInt(total, 10));
        };
        fetchSupplies();
    }, [nftContract]);

    // Función para ajustar el contador a la cantidad máxima permitida
    const handleMaxClick = () => {
        const availableToMint = maxSupply - totalSupply;

        // Si estamos en modo whitelist, tomamos el mínimo entre el límite del contrato y el availableToMint
        if (whitelistMode) {
            setMintCount(Math.min(availableToMint, whitelistMintLimit));
        } else {
            // Si no está en whitelist, solo ajustamos según el MaxSupply
            setMintCount(availableToMint);
        }
    };

    // Modificar handleMintCountChange para limitar el contador a MaxSupply - totalSupply
    const handleMintCountChange = (change) => {
        setMintCount((prevCount) => {
            // Asegurarse de que el contador no baje de 1 y no suba más allá de MaxSupply - totalSupply
            const availableToMint = maxSupply - totalSupply;
            const newCount = prevCount + change;
            return Math.max(1, Math.min(newCount, availableToMint));
        });
    };

    const startIncrement = () => {
        handleMintCountChange(1);
        incrementRef.current = setInterval(() => {
            handleMintCountChange(1);
        }, 100);
    };

    const stopIncrement = () => {
        clearInterval(incrementRef.current);
    };

    const startDecrement = () => {
        handleMintCountChange(-1);
        decrementRef.current = setInterval(() => {
            handleMintCountChange(-1);
        }, 100);
    };

    const stopDecrement = () => {
        clearInterval(decrementRef.current);
    };

    const dispatch = useDispatch();
    const nftDetails = useSelector((state) => state.nft[nftId]);
    const balance = nftDetails ? nftDetails.balance : 0;
    const allowance = 1;

    // Función para manejar la compra de NFTs
    const handleBuyNFT = async () => {
        const userAddress = await web3.eth.getAccounts();
        const userAddr = userAddress[0];

        try {
            const priceInWei = web3.utils.toWei(price.toString(), 'ether'); // Convertimos el precio a Wei

            // Si mintCount > 1, llamamos a BatchMint
            if (mintCount > 1) {
                await nftContract.methods
                    .batchMint(userAddr, mintCount)
                    .send({ from: userAddr, value: priceInWei * mintCount }); // Multiplicamos por mintCount
                console.log('BatchMint exitoso');
            } else {
                // Si mintCount es 1, llamamos a Mint sin parámetros
                await nftContract.methods
                    .mint()
                    .send({ from: userAddr, value: priceInWei });
                console.log('Mint exitoso');
            }
        } catch (error) {
            console.error('Error minteando NFTs:', error);
        }
    };

    return (
        <section className={styles.CelebrityBuyModal}>
            <div className={styles.CelebrityModalContainerBorder}>
                <div className={styles.CelebrityModalContainer}>
                    <button className={styles.Cross} onClick={onClose}></button>

                    {video !== 'undefined' && (
                        <div className={styles.BackgroundVideo}>
                            <ImageLoader video={video} />
                        </div>
                    )}

                    {image !== 'undefined' && (
                        <div className={styles.CelebrityModalNftImage}>
                            <ImageLoader image={image} />
                        </div>
                    )}

                    <div className={styles.CelebrityModalNftContainer}>
                        <div className={styles.CelebrityModalNftSubcontainerArtist}>
                            <h2>{artist}</h2>
                        </div>
                        {connected && (
                            <div className={styles.CelebrityModalNftSubcontainerName}>
                                <h2>{name}</h2>
                                <h3>{colection}</h3>
                                <p className={styles.OwnText}> You own {balance} shares</p>
                            </div>
                        )}

                        <div className={styles.CelebrityModalNftContainerInfoPc}>
                            {/* Mostrar el contador de NFTs y el texto "Set Max Available" solo cuando el countdown ha terminado */}
                            {done && (
                                <>
                                    <div className={styles.CounterContainer}>
                                        <button
                                            onMouseDown={startDecrement}
                                            onMouseUp={stopDecrement}
                                            onMouseLeave={stopDecrement}
                                            className={styles.CounterButton}
                                        >
                                            -
                                        </button>
                                        <input
                                            type="number"
                                            className={styles.CounterInput}
                                            value={mintCount}
                                            onChange={(e) =>
                                                setMintCount(
                                                    Math.max(
                                                        1,
                                                        Math.min(parseInt(e.target.value, 10) || 1, maxSupply - totalSupply)
                                                    )
                                                )
                                            }
                                            min="1"
                                        />
                                        <button
                                            onMouseDown={startIncrement}
                                            onMouseUp={stopIncrement}
                                            onMouseLeave={stopIncrement}
                                            className={styles.CounterButton}
                                        >
                                            +
                                        </button>
                                    </div>

                                    {/* Texto "Set Max Available" */}
                                    <div className={styles.MaxButtonContainer}>
                                        <span onClick={handleMaxClick} className={styles.MaxText}>
                                            Set Max Available
                                        </span>
                                    </div>
                                </>
                            )}

                            {/* Contenedor de precio siempre visible */}
                            <div className={styles.CelebrityModalPriceContainer}>
                                <p>{price * mintCount} ETH</p>
                            </div>

                            {/* Botón para comprar siempre al final y visible solo cuando el countdown ha terminado */}
                            {done && allowance > 0 && connected && (
                                <button onClick={handleBuyNFT} className={styles.CelebrityModalPurchase}>
                                    <p>Buy {mintCount}</p>
                                </button>
                            )}

                            {/* Agregar Countdown */}
                            <p className={styles.CelebrityModalTimerStart}>
                                {!done
                                    ? 'This sale will start: ' +
                                    (cutoffMonth < 10 ? '0' : '') +
                                    cutoffMonth +
                                    '.' +
                                    (cutoffDay < 10 ? '0' : '') +
                                    cutoffDay +
                                    '.' +
                                    cutoffYear +
                                    ' at ' +
                                    ' ' +
                                    (cutoffHour < 10 ? '0' : '') +
                                    cutoffHour +
                                    ':' +
                                    (cutoffMinutes < 10 ? '0' : '') +
                                    cutoffMinutes +
                                    (cutoffHour < 12 ? ' AM' : ' PM')
                                    : ''}
                            </p>

                            {!done && (
                                <div className={styles.CelebrityModalTimerContainer}>
                                    <div className={styles.CelebrityModalTimer}>
                                        <p className={styles.CelebrityModalNumber}>
                                            {(daysRemaining < 10 ? '0' : '') + daysRemaining}
                                        </p>
                                        <p className={styles.CelebrityModalTime}>Days</p>
                                    </div>
                                    <div className={styles.CelebrityModalTimer}>
                                        <p className={styles.CelebrityModalNumber}>
                                            {(hoursRemaining < 10 ? '0' : '') + hoursRemaining}
                                        </p>
                                        <p className={styles.CelebrityModalTime}>Hours</p>
                                    </div>
                                    <div className={styles.CelebrityModalTimer}>
                                        <p className={styles.CelebrityModalNumber}>
                                            {(minutesRemaining < 10 ? '0' : '') + minutesRemaining}
                                        </p>
                                        <p className={styles.CelebrityModalTime}>Minutes</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.Background}></div>
        </section>
    );
};

export default BuyModal;
